import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

import logo from "/Users/robertorosas/Documents/OkamuraN/src/images/LogoOkamura.webp";

import "./Nav.css";

const Nav = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClickBottom = () => {
    window.scrollTo(0, document.documentElement.scrollHeight);
  };
  return (
    <AppBar position="static" className="navbar-bg">
      <Container maxWidth="xl" className="navbar-container">
        <Toolbar disableGutters className="">
          <img
            style={{ maxWidth: "315px" }}
            alt="Grupo Requiez - Logo"
            src={logo}
          />
          <Box
            sx={{ flexGrow: 3, display: { xs: "flex", md: "none" } }}
            className="nav-links"
          >
            <IconButton
              size="small"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              className="colorLetter"
            >
              <MenuIcon style={{ fontSize: "3.5rem" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">
                  <HashLink className="colorLetter" to="/#products">
                    Productos
                  </HashLink>
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">
                  <Link className="colorLetter" to="/History">
                    Nosotros
                  </Link>
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">
                  <Link
                    className="colorLetter"
                    to="/Catalogo.pdf"
                    target="_blank" // Add this line
                  >
                    Catálogo
                  </Link>
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">
                  <Link className="colorLetter" to="/Greenwave">
                    Sustentabilidad
                  </Link>
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">
                  <Link className="colorLetter" to="/Contact">
                    Contacto
                  </Link>
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Box
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
            className="nav-links"
          >
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block", fontSize: "12px" }}
              component={HashLink}
              className="colorLetter"
              to="/#products"
            >
              Productos
            </Button>

            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block", fontSize: "12px" }}
              component={Link}
              className="colorLetter"
              to="/History"
            >
              Nosotros
            </Button>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block", fontSize: "12px" }}
              component={Link}

              className="colorLetter"
              to="/Catalogo.pdf"
              target="_blank"
            >
              Catálogo
            </Button>

            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block", fontSize: "12px" }}
              component={Link}
              className="colorLetter"
              to="/Greenwave"
            >
              Sustentabilidad
            </Button>
            <Button
              onClick={handleClickBottom}
              sx={{ my: 2, color: "white", display: "block", fontSize: "12px" }}
              component={Link}
              className="colorLetter"
              to="/Contact"
            >
              Contacto
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Nav;
