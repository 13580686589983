import React, { Component, Fragment } from "react";
import img1 from "../../images/face.png";
import img2 from "../../images/insta.png";

import "./Con.css";
import { Widgets } from "@material-ui/icons";

class Con extends Component {
  render() {
    return (
      <Fragment>
        <div className="containerCon">
          <div>
            <h2>GUADALAJARA</h2>
            <p>
              Avenida Paseo Punto Sur #312
              <br />
              Nivel 1, Interior V2,
              <br />
              Corporativo Punto Sur
              <br />
              CP. 45645.
              <br />
              Tlajomulco de Zúñiga, Jalisco.
              <br />
              <a href="tel:3338337788">+52 (33) 3833 7788</a>
            </p>
          </div>

          <div>
            <h2 className="containerConh2">CDMX</h2>
            <p>
              Volcán #150
              <br />
              Lomas de Chapultepec, V Sección
              <br />
              Ciudad De México
              <br />
              CP. 11000
              <br />
              <a href="tel:5527909520">+52 (55) 2790 9520</a>
            </p>
          </div>
          <div className="containerConP">
            <p>Síguenos en redes</p>
            <a href="https://www.facebook.com/OkamuraMexico" target="_blank">
              <img src={img1} alt="" />
            </a>
            <a target="_blank" href="https://www.instagram.com/okamura_mx/">
              <img src={img2} alt="" />
            </a>
          </div>
        </div>
        

      </Fragment>
    );
  }
}

export default Con;
