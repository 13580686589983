import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import image1 from "../../banners/1.jpg";
import image2 from "../../banners/2.jpg";
import image3 from "../../banners/3.jpg";
import image4 from "../../banners/4.jpg";
import image5 from "../../banners/5.jpg";

class Banner1 extends Component {
  render() {
    const images = [image1, image2, image3, image4, image5];
    const names = ['Image 1', 'Image 2', 'Image 3', 'Image 4', 'Image 5'];

    return (
      <Carousel
        nextIcon={''}
        prevIcon={''}
        nextLabel={''}
        prevLabel={''}
        style={{ width: '100%' }}
      >
        {images.map((image, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src={image}
              alt={names[index]}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    );
  }
}

export default Banner1;
