import React, { Component, Fragment } from "react";
import Header from "../Header/Header";
import Nav from "../Header/Nav";
import Banner1 from "../Banner/Banner1";

import banner1 from "../../banners/1.jpg";
import banner2 from "../../banners/2.jpg";
import banner3 from "../../banners/3.jpg";
import banner4 from "../../banners/4.jpg";
import banner5 from "../../banners/5.jpg";

class Layout extends Component {
  render() {
    return (
      <Fragment>
        <Nav />
    
        {this.props.children}
      </Fragment>
    );
  }
}

export default Layout;
