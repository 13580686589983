import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import Layout from "./components/Layout/Layout";

import Home from "./pages/Home";
import ProductView from "./pages/ProductView";
import History from "./pages/History";
import Greenwave from "./pages/Greenwave";
import OkamuraWay from "./pages/OkamuraWay";
import OurTimeline from "./pages/OurTimeline";
import MakeWith from "./pages/MakeWith";
import Contact from "./pages/Contact";


// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function ScrollToProduct() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (window.screen.width > 600) {
      window.scrollTo(900, 900);
    } else {
      window.scrollTo(600, 600);
    }
  }, [pathname]);

  return null;
}

function App() {
  return (
    <BrowserRouter basename="/">
      <Switch>
        <Layout className="App">
          <Route
            exact
            path="/"
            children={
              <div>
                <ScrollToTop />
                <Home />
              </div>
            }
          />
          <Route
            exact
            path="/product-view/:id"
            children={
              <div>
                <ScrollToTop />
                <ProductView />
              </div>
            }
          />
          <Route
            exact
            path="/history/"
            children={
              <div>
                <ScrollToTop />
                <History />
              </div>
            }
          />
          <Route
            exact
            path="/okamuraWay/"
            children={
              <div>
                <ScrollToTop />
                <OkamuraWay />
              </div>
            }
          />
          <Route
            exact
            path="/OurTimeline/"
            children={
              <div>
                <ScrollToTop />
                <OurTimeline />
              </div>
            }
          />
          <Route
            exact
            path="/makeWith/"
            children={
              <div>
                <ScrollToTop />
                <MakeWith />
              </div>
            }
          />
          <Route
            exact
            path="/greenwave/"
            children={
              <div>
                <ScrollToTop />
                <Greenwave />
              </div>
            }
          />
          <Route
            exact
            path="/contact/"
            children={
              <div>
                <ScrollToTop />
                <Contact />
              </div>
            }
          />
        </Layout>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
