import React, { Component, Fragment } from "react";
import img1 from "../../images/1.webp";
import img2 from "../../images/2.webp";
import img3 from "../../images/3.webp";
import "./Us.css";
import SpaceRight from "../SpaceRight/SpaceRight";

class Us extends Component {
  render() {
    return (
      <Fragment>
        <h2 className="us-titulo">¿Quiénes somos?</h2>
        <p className="us-description">
          Okamura, tuvo sus inicios en 1945, con sede en la ciudad de Okamura en
          Yokohama. El espíritu fundador sigue presente hoy en día en los cinco
          principios de la Filosofía Corporativa: creación, cooperación,
          economía, ahorro y servicio. Desde su fundación, Okamura ha brindado
          productos y servicios de alta calidad para oficinas, educación,
          atención médica, instalaciones comerciales y centros de distribución
          bajo el lema "La calidad se paga sola". La fortaleza de Okamura radica
          en su amplio conocimiento y experiencia técnica en muchos campos, la
          base para crear espacios cómodos para clientes de todo el mundo.
        </p>

        <SpaceRight
          name="Okamura Way"
          image={img1}
          info="Representa las ideas y los valores que forman la base de esta empresa. Estamos desarrollando una identidad para compartir Okamura Way entre nuestras partes interesadas internas y pretendemos reflejarlo en todas nuestras actividades."
          link={<a href="/OkamuraWay">Saber más</a>}
        />
        <SpaceRight
          name="Nuestra Historia"
          image={img2}
          info="Un espíritu transmitido en una linea ininterrumpida: Dedicación
          incansable a crear algo de la nada."
          link={<a href="/OurTimeline">Saber más</a>}
        />
        <SpaceRight
          name="Make With"
          image={img3}
          info="Desde el concepto hasta la finalización, los productos de Okamura 
          estan llenos de espíritu creativo. Incluso los detalles más pequeños, superan
          los límites del diseño convencional, por lo que creamos algo completamente
          nuevo y único, una y otra vez. Todo con el objetivo final de hacer evolucionar
          el lugar de trabajo, creando entornos más productivos, más creativos y más
          felices."
          link={<a href="/MakeWith">Saber más</a>}
        />
      </Fragment>
    );
  }
}

export default Us;
